@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  body {
    @apply text-[#22343D] box-border;
  }
}

@layer components {
  .primary-button {
    @apply px-8 py-2 whitespace-nowrap font-semibold text-lg bg-[#263BE3] text-white rounded-md cursor-pointer border-[#263BE3] hover:bg-white border-2 hover:text-[#263BE3] transition-all duration-200 active:scale-90;
  }
  .primary-button2 {
    @apply px-8 py-2 whitespace-nowrap font-semibold text-lg bg-[#263BE3] text-white rounded-md cursor-pointer border-[#263BE3] hover:bg-[#1E1FBA] border-2 hover:text-white transition-all duration-200 active:scale-90;
  }
  .secondary-button {
    @apply px-8 py-2 whitespace-nowrap font-semibold text-lg border-2 border-[#BCD0E5] rounded-md cursor-pointer hover:border-[#263BE3] hover:text-[#263BE3] duration-200 active:scale-90;
  }
  .primary-button-white {
    @apply px-8 py-2 primary-button bg-white text-lg text-primary hover:bg-[#9FCDFF] hover:text-primary hover:border-[#263BE3];
  }
  .nav-item {
    @apply cursor-pointer px-4 hover:font-semibold hover:scale-105 transition transform active:scale-90;
  }
  .container {
    @apply max-w-7xl m-auto px-8 sm:px-12;
  }
  .icon-button {
    @apply hover:scale-125 active:scale-95 cursor-pointer duration-200;
  }
}

body {
  font-family: "Poppins", sans-serif;
}
