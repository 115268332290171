@keyframes scroll-inverse {
  0% {
    transform: translateX(0);
  }
  to {
    transform: translateX(-100%);
  }
}

.animate-scroll-inverse {
  animation: scroll-inverse 10s linear infinite;
}

.AutoScrollingCards .QuoteCard {
  width: calc(100vw - 48px);
}

@keyframes scroll-prev {
  0% {
    transform: translateX(-100%);
  }
  to {
    transform: translateX(0%);
  }
}

.animate-scroll-prev {
  animation: scroll-prev 0.5s linear;
}

@keyframes scroll-next {
  0% {
    transform: translateX(100%);
  }
  to {
    transform: translateX(0%);
  }
}

.animate-scroll-next {
  animation: scroll-next 0.5s linear;
}

.hidden-exit {
  display: none;
}