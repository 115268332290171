.hsg-footer {
  background-color: #0A0E23;
  color: #fff;
}

@media (max-width: 927px) {
  .hsg-footer {
    overflow-x: hidden;
  }
}

.hsg-footer a:not([class*="cl-button"]) {
  color: #b6c7d6;
}

.hsg-footer a:not([class*="cl-button"]):focus-visible {
  outline: 2px solid #5fa3d4;
  outline-offset: 2px;
}

@media (min-width: 928px) {
  .hsg-footer a:not([class*="cl-button"]):hover {
    color: #88bde3;
  }
}

.hsg-footer__copyright li {
  display: inline-block;
}

.hsg-footer__copyright li a {
  display: block;
  padding: 0.5rem 1rem;
}

.hsg-footer__dropdown > label,
.hsg-footer__nav-item a {
  display: block;
  font-size: 0.75rem;
  font-weight: 600;
  line-height: 2.4;
  text-decoration: none;
}

.hsg-footer {
  padding: 0 0 2rem;
  transition: all 0.3s ease;
}

@media (min-width: 928px) {
  .hsg-footer {
    padding: 3rem 2rem;
  }
}

@media (max-width: 927px) {
  .hsg-footer.active .hsg-footer__nav-column:not(.active) {
    display: none;
  }
}

.hsg-footer.active .hsg-footer__logo-caret {
  display: none;
}

@media (max-width: 927px) {
  .hsg-footer.active .hsg-footer__logo-caret {
    align-items: center;
    background: none;
    border: 0;
    display: flex;
    justify-content: space-between;
    padding: 1rem 1rem 0;
    width: 100%;
  }
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close {
  cursor: pointer;
  display: inline-block;
  position: relative;
  width: 1.5rem;
}

@media (min-width: 928px) {
  .hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close {
    display: none;
  }
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:after,
.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:before {
  background-color: #fff;
  border-radius: 1rem;
  content: "";
  display: block;
  height: 0.2rem;
  left: 0;
  position: absolute;
  top: 0;
  width: 90%;
}

@media (min-width: 928px) {
  .hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:after,
  .hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:before {
    display: none;
  }
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:before {
  transform: rotate(-135deg);
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__close:after {
  transform: rotate(-45deg);
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__logo-caret-home {
  cursor: pointer;
  position: relative;
}

@media (max-width: 927px) {
  .hsg-footer.active .hsg-footer__logo-caret .hsg-footer__logo-caret-home {
    color: #fff;
    display: inline-block;
    font-size: 0.875rem;
    padding-left: 2rem;
  }
}

.hsg-footer.active .hsg-footer__logo-caret .hsg-footer__logo-caret-home:before {
  display: none;
}

@media (max-width: 927px) {
  .hsg-footer.active
    .hsg-footer__logo-caret
    .hsg-footer__logo-caret-home:before {
    color: #fff;
    content: "";
    display: inline-block;
    font-family: hsg-icons;
    font-size: 1.125rem;
    font-weight: 600;
    left: 0;
    position: absolute;
    transform: rotate(90deg);
    vertical-align: middle;
  }

  .hsg-ie11
    .hsg-footer.active
    .hsg-footer__logo-caret
    .hsg-footer__logo-caret-home:before {
    left: -0.5rem;
    top: -0.15rem;
  }
}

.hsg-footer__logo-caret {
  display: none;
}

/* Styles pour les grands écrans */
@media (min-width: 768px) {
  .hsg-footer__layout.scale-125 {
    /* box-sizing: content-box; */
    margin-left: 11rem;
    margin-right: 11rem;
    /* max-width: 1080px; */
    padding: 5rem;
  }
}

@media (min-width: 768px) {
  .hsg-footer__layout.scale-100 {
    box-sizing: content-box;
    margin-left: 21rem;
    margin-right: 21rem;
    max-width: full;
    padding: 0;
  }
}
/* Styles pour les petits écrans */
@media (max-width: 767px) {
  .hsg-footer__layout {
    box-sizing: content-box;
    margin-left: auto;
    margin-right: auto;
    max-width: 1080px;
    padding: 0;
  }
}


.hsg-footer__nav {
  flex-direction: column;
}

@media (min-width: 928px) {
  .hsg-footer__nav {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-bottom: 1.65em;
    width: 100%;
  }

  .hsg-ie11 .hsg-footer__nav {
    margin-bottom: 5rem;
  }
}

.hsg-footer__nav > ul {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 0;
  width: 100%;
}

@media (min-width: 928px) {
  .hsg-footer__nav > ul {
    flex-direction: row;
  }
}

.hsg-footer__nav-column {
  border-bottom: 1px solid #7691ad;
  flex: 1;
  padding: 0.45rem 0;
  position: relative;
  transition: all 0.3s ease;
}

@media (min-width: 928px) {
  .hsg-footer__nav-column {
    border-bottom: 0;
    margin-bottom: 0;
    text-align: left;
  }
}

@media (max-width: 1024px) {
  .hsg-footer__nav-column {
    flex: 0;
  }

  .hsg-ie11 .hsg-footer__nav-column {
    display: inline-block;
    width: 100%;
  }
}

.hsg-footer__nav-column:first-child {
  padding-left: 0;
}

.hsg-footer__nav-column:last-child {
  padding-right: 0;
}

.hsg-footer__nav-column > ul {
  display: none;
  flex-direction: column;
  transition: all 0.3s ease;
}

@media (min-width: 928px) {
  .hsg-footer__nav-column > ul {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: 0;
  }
}

.hsg-footer__nav-column > input {
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 0;
  clip: rect(0, 0, 0, 0);
}

@media (max-width: 927px) {
  .hsg-footer__nav-column > input {
    height: 100%;
    width: 100%;
  }

  .hsg-footer__nav-column.active {
    flex-wrap: nowrap;
  }

  .hsg-footer__nav-column.active .hsg-footer__nav-toggle {
    font-size: 1.25rem;
    font-weight: 300;
    margin-bottom: 1rem;
    text-align: center;
  }
}

.hsg-footer__nav-column.active .hsg-footer__nav-toggle:after {
  display: none;
}

@media (max-width: 927px) {
  .hsg-footer__nav-column.active > ul {
    display: flex;
    height: 100%;
    padding: 0 1rem;
    position: static;
  }

  .hsg-ie11 .hsg-footer__nav-column.active > ul {
    display: block;
  }

  .hsg-footer__nav-column.active > ul li {
    align-items: center;
    background-color: #fff;
    border: 1px solid #2e475d;
    border-radius: 3px;
    display: flex;
    margin: 0 0 1rem;
    position: relative;
  }
}

.hsg-footer__nav-column.active > ul li:after {
  display: none;
}

@media (max-width: 927px) {
  .hsg-footer__nav-column.active > ul li:after {
    color: #0068b1;
    content: "";
    display: inline-block;
    font-family: hsg-icons;
    font-size: 1.125rem;
    font-weight: 600;
    position: absolute;
    right: 1rem;
    transform: rotate(-90deg);
    vertical-align: middle;
  }
}

.hsg-footer__nav-column.active > ul a {
  position: relative;
}

@media (max-width: 927px) {
  .hsg-footer__nav-column.active > ul a {
    color: #2e475d;
    font-size: 0.875rem;
    font-weight: 500;
    padding: 1rem;
  }
}

.hsg-footer__nav-column .hsg-footer__nav-heading {
  line-height: 1.6;
  margin: 0;
}

.hsg-footer__nav-column .hsg-footer__nav-toggle {
  background: none;
  border: 0;
  color: #fff;
  cursor: pointer;
  display: block;
  font-size: 1rem;
  font-weight: 600;
  padding: 0.5rem 1rem;
  position: relative;
  text-align: left;
  transition: all 0.3s ease;
  width: 100%;
}

@media (min-width: 928px) {
  .hsg-footer__nav-column .hsg-footer__nav-toggle {
    font-size: 0.875rem;
    padding: 0 0 1rem;
  }
}

.hsg-footer__nav-column .hsg-footer__nav-toggle[aria-expanded="true"] {
  pointer-events: none;
}

.hsg-footer__nav-column .hsg-footer__nav-toggle:after {
  /* content: ""; */
  display: inline-block;
  font-family: hsg-icons;
  font-size: 1.125rem;
  position: absolute;
  right: 1rem;
  transform: rotate(-90deg);
  vertical-align: middle;
}

@media (min-width: 928px) {
  .hsg-footer__nav-column .hsg-footer__nav-toggle:after {
    content: none;
  }
}

.hsg-footer__nav-column-left {
  display: flex;
  flex: 1 0 17%;
  flex-direction: column;
  flex-wrap: wrap;
}

.hsg-footer__nav-column-left > ul {
  column-count: 1;
}

@media (min-width: 928px) {
  .hsg-footer__nav-column-left > ul {
    column-count: 2;
    display: inline-block;
  }
}

.hsg-footer__nav-column .hsg-footer__nav-item {
  flex: 1 1 45%;
  margin-right: 1em;
}

.hsg-footer__nav-left,
.hsg-footer__nav-right {
  display: flex;
}

@media (min-width: 928px) {
  .hsg-footer__nav-left,
  .hsg-footer__nav-right {
    flex-direction: row;
  }
}

@media (max-width: 927px) {
  .hsg-ie11 .hsg-footer__nav-left,
  .hsg-ie11 .hsg-footer__nav-right {
    display: inline-block;
    width: 100%;
  }
}

.hsg-footer__nav-left {
  border-right: 0;
  width: 100%;
}

@media (min-width: 928px) {
  .hsg-footer__nav-left {
    border-right: 1px solid #7691ad;
    width: 40%;
  }
}

.hsg-footer__nav-right {
  flex-direction: column;
}

@media (min-width: 928px) {
  .hsg-footer__nav-right {
    flex: 1 0 30%;
    flex-direction: row;
    justify-content: space-between;
    /* margin-left: 3.25rem; */
  }
}

.hsg-footer__nav-full,
.hsg-footer__nav-split {
  display: flex;
  flex-direction: column;
}

@media (max-width: 927px) {
  .hsg-ie11 .hsg-footer__nav-full,
  .hsg-ie11 .hsg-footer__nav-split {
    display: inline-block;
    width: 100%;
  }
}

.hsg-footer__nav-full {
  flex: 1;
}

@media (min-width: 928px) {
  .hsg-footer__nav-full:last-child {
    margin-right: 1rem;
  }
}

.hsg-footer__nav-split {
  flex: 1;
}

@media (min-width: 928px) {
  .hsg-footer__nav-split > section {
    margin-bottom: 1rem;
  }
}

.hsg-footer__nav-split > section > ul {
  flex-direction: column;
}

.hsg-footer__nav-split .hsg-footer__nav-item {
  margin-right: 0;
}

.hsg-ie11 .hsg-footer__nav-split .hsg-footer__nav-item {
  flex: 0 0 auto;
}

.hsg-footer__nav-item {
  border-top: 1px solid #3e5974;
}

@media (min-width: 928px) {
  .hsg-footer__nav-item {
    border-top: 0;
  }
}

.hsg-footer__nav-item a {
  width: 100%;
}

@media (min-width: 928px) {
  .hsg-footer__nav-item a:not([class*="cl-button"]):hover {
    color: #88bde3;
    font-weight: 600;
    text-decoration: underline;
  }
}

.hsg-footer__nav-item a.hsg-en-link {
  display: inline-block;
  padding-right: 2.25rem;
  position: relative;
}

.hsg-footer__nav-item a.hsg-en-link:after {
  background-color: #ff5c35;
  border-radius: 0.1875rem;
  box-sizing: content-box;
  color: #fff;
  content: "EN";
  font-size: 0.625rem;
  font-weight: 600;
  height: 0.5rem;
  line-height: 0.8;
  margin-left: 0.5rem;
  opacity: 1;
  padding: 0.25rem;
  transition: opacity 0.15s ease-out;
}

[lang="ja"] .hsg-footer__nav-item a.hsg-en-link:after {
  content: "英";
}

.high-contrast .hsg-footer__nav-item a.hsg-en-link:after {
  background-color: #1d0cff;
}

.hsg-footer__nav-item > a {
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .hsg-footer__nav-item > a {
    padding: 0.125rem 0;
  }
}

.hsg-footer__nav-item--contact-links {
  order: 10;
}

@media (max-width: 767px) {
  .hsg-footer__nav-item--contact-links {
    display: none;
  }
}

.hsg-ie11 .hsg-footer__nav-item--contact-links {
  margin-top: 4rem;
}

.hsg-footer__dropdown > input {
  height: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  top: 0;
  width: 0;
  clip: rect(0, 0, 0, 0);
}

.hsg-footer__dropdown > input:checked ~ span {
  display: flex;
}

.hsg-footer__dropdown > label {
  cursor: pointer;
  padding: 1rem 0;
}

@media (min-width: 768px) {
  .hsg-footer__dropdown > label {
    padding: 0.5rem 0;
  }
}

.hsg-footer__dropdown > label:after {
  content: "";
  display: inline;
  font-family: hsg-icons;
  font-size: 7px;
  padding-left: 0.5rem;
}

.hsg-footer__dropdown > span {
  display: none;
  flex-direction: column;
  padding-bottom: 1rem;
  width: 100%;
}

@media (min-width: 768px) {
  .hsg-footer__dropdown > span {
    padding-bottom: 0;
  }
}

.hsg-footer__dropdown > span a {
  font-size: 0.75rem;
  padding: 0.5rem 0;
}

.hsg-footer__contact-links {
  box-sizing: content-box;
  margin-left: auto;
  margin-right: auto;
  max-width: 1080px;
  padding: 0 1rem;
  position: relative;
  text-align: center;
}

@media (min-width: 928px) {
  .hsg-footer__contact-links {
    padding: 0;
  }
}

.hsg-footer__contact-links:after,
.hsg-footer__contact-links:before {
  border: 0;
  width: 30%;
}

@media (min-width: 928px) {
  .hsg-footer__contact-links:after,
  .hsg-footer__contact-links:before {
    border-top: 1px solid #7691ad;
    content: "";
    position: absolute;
    top: 50%;
    width: 33%;
  }

  .hsg-footer__contact-links:before {
    right: 0;
  }

  .hsg-footer__contact-links:after {
    left: 0;
  }
}

.hsg-footer__contact-links.social-cl .hsg-footer__social {
  display: flex;
  justify-content: center;
}

@media (max-width: 599px) {
  .hsg-footer__contact-links.social-cl .hsg-footer__social {
    gap: 0.125rem;
  }
}

@media (min-width: 928px) {
  .hsg-footer__contact-links.social-cl:after,
  .hsg-footer__contact-links.social-cl:before {
    width: 29%;
  }
}

.hsg-footer__cta {
  margin: 3rem 0 0.75rem;
}

@media (min-width: 768px) {
  .hsg-footer__cta {
    margin: 0.75rem 0;
  }
}

.hsg-footer__social {
  margin-top: 1em;
  position: relative;
  width: 100%;
}

@media (min-width: 768px) {
  .hsg-footer__social {
    align-items: center;
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
}

.hsg-footer__social li {
  display: inline-block;
  margin: 0 0.25em;
}

@media (min-width: 768px) {
  .hsg-footer__social li {
    margin: 0 1em;
  }
}

.hsg-footer__social li a {
  display: block;
  padding: 0.45rem;
  text-decoration: none;
}

@media (min-width: 768px) {
  .hsg-footer__social li a {
    padding: 0.75rem 0;
  }
}

.hsg-footer__social li a span {
  height: 1px;
  left: -10000px;
  overflow: hidden;
  position: absolute;
  top: auto;
  width: 1px;
}

.hsg-footer__social li a .cl-icon {
  height: 23px;
  width: 26px;
}

.hsg-footer__social li a .cl-icon use {
  fill: #b6c7d6;
}

.hsg-footer__social li a .hsg-icon:before {
  font-size: 1.35rem;
}

.hsg-footer__bottom {
  display: flex;
  flex-direction: column;
  padding: 2rem 2rem 0;
  width: 100%;
}

@media (min-width: 1025px) {
  .hsg-footer__bottom {
    align-items: center;
    justify-content: center;
    padding: 1rem 0 0;
  }
}

.hsg-footer__apps {
  display: flex;
  justify-content: center;
  margin-bottom: 1rem;
}

@media (min-width: 1025px) {
  .hsg-footer__apps {
    display: none;
  }
}

.hsg-footer__app {
  display: flex;
  height: auto;
  max-width: 8.5rem;
}

@media (max-width: 927px) {
  .hsg-ie11 .hsg-footer__app {
    flex-shrink: 0;
    width: 100%;
  }

  .hsg-ie11 .hsg-footer__app img {
    height: auto;
    width: 100%;
  }
}

.hsg-footer__app:first-child {
  margin-right: 1rem;
}

.hsg-footer__logo {
  margin-top: 2rem;
  text-align: center;
}

@media (min-width: 768px) {
  .hsg-footer__logo {
    align-items: center;
    display: flex;
    flex-direction: column;
    margin-top: 0;
    order: 0;
  }
}

.hsg-footer__logo img {
  display: block;
  margin: 0 auto;
  max-width: 100px;
}

@media (min-width: 1025px) {
  .hsg-footer__logo img {
    margin: 0;
  }
}

.hsg-footer__logo p {
  color: #516f90;
  color: #b6c7d6;
  font-size: 0.75rem;
  font-weight: 500;
  line-height: 1.67;
  line-height: 0.875em;
}

@media (min-width: 768px) {
  .hsg-footer__logo p {
    margin-bottom: 0;
  }
}

.hsg-footer__copyright ul {
  position: relative;
  text-align: center;
}

.hsg-footer__copyright ul > li {
  position: relative;
}

.hsg-footer__copyright ul > li:after {
  color: #7691ad;
  content: "|";
  display: inline-block;
  font-size: 0.75em;
  position: absolute;
  right: -0.25em;
  top: 25%;
}

.hsg-footer__copyright ul > li:last-of-type:after {
  content: "";
}

.hsg-footer__copyright a:not([class*="cta"]) {
  color: #fff;
  font-size: 0.875rem;
  font-weight: 600;
  text-decoration: underline;
}
